<!--
 * @Author: zhao && 1219714162@qq.com
 * @Date: 2023-04-27 18:16:08
 * @Description: file content
-->
<template>
  <transition name="el-fade-in-linear">
    <router-view />
  </transition>
</template>
<script>
export default {
  name: 'App',
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  border: none;
  outline: none;
  list-style: none;
  user-select: none;
}
::-webkit-scrollbar {
  display: none;
}
</style>
