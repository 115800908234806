/*
 * @Author: zhao && 1219714162@qq.com
 * @Date: 2023-04-27 19:00:53
 * @Description: file content
 */
import Vue from 'vue';
import Vuex from 'vuex';
import { createVuexPersistedState } from 'vue-persistedstate';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  plugins: [
    createVuexPersistedState({
      key: 'vuex',
      storage: window.sessionStorage,
      whiteList: [],
      blackList: [],
    }),
  ],
});
