/*
 * @Author: zhao && 1219714162@qq.com
 * @Date: 2023-05-21 16:20:29
 * @Description: file content
 */
const httpCache = new Map();
const coolCache = new Set();

class Fn {
  constructor(url) {
    this.url = url;
  }

  // 正常处理
  async handler(resolve, rs) {
    const { data, list, msg } = await this.after(await rs);
    resolve({ data, list, msg });
  }

  async before(data) {
    if (/update|add|create/g.test(this.url)) {
      if (coolCache.has(this.url)) return Promise.reject('双击阻挡');
      coolCache.add(this.url);
      setTimeout(() => coolCache.delete(this.url), 1000);
      await window.$verify();
    }
    //数字转字符串
    data = JSON.parse(JSON.stringify(data));
    for (let i in data) {
      if (data[i] instanceof Object) {
        for (const key in data[i]) {
          if (!data[i][key] && data[i][key] !== 0) data[i][key] = '';
          if (!isNaN(data[i][key])) data[i][key] = String(data[i][key]);
        }
      } else {
        if (!data[i] && data[i] !== 0) data[i] = '';
        if (!isNaN(data[i])) data[i] = String(data[i]);
      }
    }
    return JSON.stringify(data);
  }
  async after(rs) {
    if (rs.state === 1) {
      return rs;
    } else {
      return Promise.reject(rs.msg);
    }
  }
  end(key) {
    httpCache.delete(key);
  }
}

const http = async function (path = '', data = {}, source, method = 'POST') {
  const key = path + JSON.stringify(data);
  source = source === 1 || source === undefined ? 'one' : 'second';
  const fn = new Fn(path);
  if (!(data instanceof FormData)) data = await fn.before(data);
  if (httpCache.has(key)) return httpCache.get(key);
  const url = 'https://crms.yunxkeji.com/v2/' + path;
  const will = new Promise((resolve, reject) => {
    fetch(url, { method, body: data })
      .then((rs) => fn.handler(resolve, rs.json()))
      .catch((e) => reject(e))
      .finally(() => fn.end(key, path));
  });
  httpCache.set(key, will);
  return will;
};
http.post = async function (url, qs) {
  const rs = await fetch(url, {
    method: 'post',
    body: JSON.stringify(qs),
    headers: { 'Content-Type': 'application/json' },
  });
  if (rs.headers.get('content-type').match('json')) return rs.json();
  const blob = await rs.blob();
  return URL.createObjectURL(blob);
};
export default http;
window.http = http;
