/*
 * @Author: zhao && 1219714162@qq.com
 * @Date: 2023-04-27 19:02:12
 * @Description: file content
 */
import Vue from 'vue';
import Router from 'vue-router';

const originPush = Router.prototype.push;
Router.prototype.push = function (raw) {
  originPush.call(this, raw).catch((e) => e);
};

Vue.use(Router);
const router = new Router({
  routes: [
    { path: '/', redirect: 'index' },
    { path: '/index', name: 'index', component: () => import('@/views/index') },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.fullPath === from.fullPath) {
    next(false);
  } else {
    const exist = router.getRoutes().some((_) => _.name === to.name);
    if (exist) {
      next();
    }
  }
});
export default router;
