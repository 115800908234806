/*
 * @Author: zhao && 1219714162@qq.com
 * @Date: 2023-04-27 19:01:35
 * @Description: file content
 */
import http from '../http';

export default {
  servicelist: (qs) => http('big-picture/service-cus', qs), //示例地址 到时候看接口来改
  sProportion: (qs) => http('big-picture/service-data', qs),
  citylist: (qs) => http('big-picture/user-rank', qs),
  trendlist: (qs) => http('big-picture/month-data', qs),
  gmvlist: (qs) => http('big-picture/service-data', qs),
  teamlist: (qs) => http('big-picture/dep-rank', qs),
};
