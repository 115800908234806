import Vue from 'vue';
import App from './App.vue';
import { router, store } from '@/utils';
import '@/element-ui/theme/index.css';
import Element from 'element-ui';

Vue.use(Element);
Vue.config.productionTip = false;
Vue.config.warnHandler = () => {};
Vue.config.errorHandler = () => {};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
