import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(isLeapYear);
dayjs.extend(relativeTime);
dayjs.locale('zh-cn');
window.dayjs = dayjs;
dayjs.__proto__.sta = (t = 'month', d) => dayjs(d).startOf(t).format('YYYY-MM-DD');
dayjs.__proto__.end = (t = 'month', d) => dayjs(d).endOf(t).format('YYYY-MM-DD');
