/*
 * @Author: zhao && 1219714162@qq.com
 * @Date: 2023-04-27 18:56:02
 * @Description: file content
 */
window.cache = function (k = '', v) {
  if (v === null) return localStorage.removeItem(k);
  if (v !== undefined) return localStorage.setItem(k, JSON.stringify(v));
  return JSON.parse(localStorage.getItem(k)) ?? {};
};
